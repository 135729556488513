import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 打包时打开
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),  // 开发时打开
  // history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('@/views/index.vue'),
      meta: {
        title: '清联资本官方网站',
      }
    },
    {
      path: '/businessInvestment',
      name: 'businessInvestment',
      component: () => import('@/views/businessInvestment.vue'),
      meta: {
        title: '基金投资',
      }
    }, {
      path: '/investment',
      name: 'investment',
      component: () => import('@/views/investment.vue'),
      meta: {
        title: '企业服务',
      }
    }, {
      path: '/privateBoard',
      name: 'privateBoard',
      component: () => import('@/views/privateBoard.vue'),
      meta: {
        title: '私董会',
      }
    }, {
      path: '/studentNews',
      name: 'studentNews',
      component: () => import('@/views/studentNews.vue'),
      meta: {
        title: '联盟速递',
      }
    }, {
      path: '/familyClub',
      name: 'familyClub',
      component: () => import('@/views/familyClub.vue'),
      meta: {
        title: '家族俱乐部',
      }
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('@/views/team.vue'),
      meta: {
        title: '专业团队',
      }
    },
    {
      path: '/talents',
      name: 'talents',
      component: () => import('@/views/talents.vue'),
      meta: {
        title: '人才培养',
      }
    },
    {
      path: '/contactUs',
      name: 'contactUs',
      component: () => import('@/views/contactUs.vue'),
      meta: {
        title: '联系我们',
      }
    }
  ]
})

export default router
