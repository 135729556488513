<template>
  <div id="app" class="p-0 overflow-hidden">
    <div class="row">
      <div class="col-12 container">
        <HeaderNav></HeaderNav>
        <div class="min-h-55">
          <router-view />
        </div>
      </div>
      <div class="col-12 ">
        <FooterQnav></FooterQnav>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/headerNav.vue'
import FooterQnav from '@/components/footerQnav.vue'

export default {
  name: 'App',
  components: {
    HeaderNav,
    FooterQnav
  },
}
</script>
<style  lang="scss" >
@import '@/assets/css/font-awesome.min.css';
@import '@/assets/css/ionicons.min.css';
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/set1.css';
@import '@/assets/css/normalize.css';
@import '@/assets/css/component.css';
@import '@/assets/css/hs.megamenu.css';
@import '@/assets/css/main.css';
@import '@/assets/css/theme.css';
@import '@/assets/css/style.css';




#app {
  .headerNav+.el-overlay {
    z-index: 20 !important;
    background-color: rgba(0, 0, 0, 0);

    .nav-drawer {
      background-color: rgba(0, 0, 0, 0.85);
    }


  }


}
</style>
