<template>
    <div class="footerQnav w-100 pb-3 pt-5 text-white bg-dark ">
        <div class="container section">
            <div class="row justify-content-md-between pt-4 px-2">

                <div class=" col-sm-auto order-lg-4 mb-7 mb-lg-0 pb-5 text-center text-sm-left">
                    <h3 class="h6 text-white mb-3">公司地址</h3>

                    <p class="small opacity-75 mb-1">北京市朝阳区安定路5号院北投大厦A座6层</p>
                    <p class="small opacity-75 mb-1">邮政编码：10020</p>
                    <p class="small opacity-75 mb-1">邮箱：service@tsinglen.com</p>
                </div>

                <div class="  col-sm-auto order-lg-6 mb-7 mb-lg-0 pb-5 text-center text-sm-left">
                    <h3 class="h6 text-white mb-3">联系我们</h3>

                    <!-- 列表 -->
                    <div class="list-group list-group-flush list-group-transparent">
                        <a class="list-group-item list-group-item-action p-0" href="#">
                            <span class="fab fa fa-phone min-width-3 text-center mr-2"></span>
                            010-64210540
                        </a>
                        <router-link :to="'contactUs'">
                            <a class="list-group-item list-group-item-action" href="#">
                                <span class="fab fa fa-map-marker min-width-3 text-center mr-2"></span>
                                查看联系方式
                            </a>
                        </router-link>

                    </div>
                    <!-- 列表结束 -->
                </div>

                <div class="col-lg-6 order-lg-1 d-flex align-items-start flex-column text-center text-sm-left pt-0 pt-sm-4">
                    <!-- Logo -->
                    <!-- <a class="d-inline-block mb-5 m-auto m-sm-0 pb-3 w-100 px-2" href="index.html" aria-label="Space">
                        <img src="static/img/logos/logo.svg" alt="Logo" style="width: 50px; max-width: 100%;">
                    </a> -->
                    <p class="text-muted w-100 m-0 h6">北京清联投资顾问有限公司</p>
                    <!-- End Logo -->
                    <p class="small text-muted w-100">All rights reserved. &copy; Tsinglen. 2018 - 2021 Htmlstream. <br />
                        <a href="https://beian.miit.gov.cn/" target="_blank" class="text-white">京ICP备17066174号-1</a>
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "footerQnav"
}
</script>

<style scoped></style>