<template>
  <div class="headerNav">
    <div id="ha-header" class="ha-header ha-header-large z-index-1000  ">
      <div class="ha-header-perspective">
        <div class="ha-header-front">
          <header id="header"
            class="u-header u-header--modern u-header--bordered u-header--bg-transparent u-header--white-nav-links u-header--sticky-top-lg">
            <div class="u-header__section">
              <div id="logoAndNav" class="container">
                <nav class="js-mega-menu navbar navbar-expand-lg u-header__navbar">
                  <!-- LOGO -->
                  <div class="u-header__navbar-brand-wrapper position-absolute top left w-100 h-100 z-index-0">
                    <router-link :to="'/'">
                      <h1 class="w-100 logo h-100"></h1>
                    </router-link>
                  </div>
                  <div class="text-white w-100 text-right d-md-none miniOpen">
                    <div class="nav_btn " @click="toggleDrawerMini" :class="drawerVisibleMini ? 'active' : ''">
                      <span class="top"></span>
                      <span class="middle"></span>
                      <span class="bottom"></span>
                    </div>
                  </div>

                  <!-- 导航 -->
                  <div id="navBar" class="collapse navbar-collapse u-header__navbar-collapse py-0 p-sm-0 p-3">
                    <ul class="navbar-nav u-header__navbar-nav pt-5 pt-sm-0 mt-4 mt-sm-0 px-4 px-sm-0 text-center">
                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4 text-center">
                        <router-link :to="'/'">
                          <a id="homeMegaMenu" class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            首页
                          </a>
                        </router-link>
                      </li>

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <a id="shopMegaMenu" class="nav-link u-header__nav-link font-weight-bold" href="javascript:;"
                          aria-haspopup="true" aria-expanded="false" aria-labelledby="shopSubMenu">
                          企业服务
                          <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                        </a>
                        <!-- bu -->
                        <ul id="shopSubMenu" class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                          style="min-width: 220px;" aria-labelledby="shopMegaMenu">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <router-link :to="'privateBoard'">
                              <a class="nav-link u-header__sub-menu-nav-link" href="#">私董会</a>
                            </router-link>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <router-link :to="'studentNews'">
                              <a class="nav-link u-header__sub-menu-nav-link" href="#">联盟速递</a>
                            </router-link>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <router-link :to="'familyClub'">
                              <a class="nav-link u-header__sub-menu-nav-link" href="#">企业家俱乐部</a>
                            </router-link>
                          </li>
                        </ul>
                      </li>

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4 text-center">
                        <router-link :to="'businessInvestment'">
                          <a id="homeMegaMenu" class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            基金投资
                          </a>
                        </router-link>
                      </li>
                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'contactUs'">
                          <a id="callme" class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            联系我们
                          </a>
                        </router-link>
                      </li>

                    </ul>
                  </div>

                  <!-- 移动版导航 -->


                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>

    <!--当屏幕滚动到该位置时导航透明-->
    <div class="bg-danger ha-waypoint position-absolute z-index-0" style=" min-height:55rem"
      data-animate-down="ha-header-color"></div>

    <!--当屏幕滚动到该位置时导航背景白色-->
    <div class="bg-danger ha-waypoint position-absolute z-index-0" data-animate-down="ha-header-show"
      data-animate-up="ha-header-color" style="top:55rem">
    </div>

  </div>

  <el-drawer v-model="drawerVisibleMini" class="nav-drawer" :show-close="false" :destroy-on-close="true" size="60%"
    direction="ttb" :with-header="false">
    <div class="drawerMiniBox">
      <div class="navBox  pt-5">
        <el-menu default-active="2" class="el-menu-vertical-demo" :router="true" :menu-active-class="true"
          background-color="rgba(0, 0, 0, 0)" @open="handleOpen" @close="handleClose">
          <el-menu-item class="mrff" v-for="(item, index) in miniMenuItems" :key="index" :index="item.path"
            @click="drawerVisibleMini = false">
            {{ item.name }}
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { ref } from 'vue'
import { megamenu } from '@/assets/js/touchvue.js'
const drawer = ref(false)

export default {
  name: "headerNav",
  components: {
    megamenu
  },
  data() {
    return {
      drawer: false,
      activeIndex: '',
      drawerVisible: false,
      drawerVisibleMini: false,
      miniMenuItems: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '私董会',
          path: '/privateBoard'
        },
        {
          name: '联盟速递',
          path: '/studentNews'
        },
        {
          name: '企业家俱乐部',
          path: '/familyClub'
        },
        {
          name: '基金投资',
          path: '/businessInvestment'
        },
        {
          name: '联系我们',
          path: '/contactUs'
        }
      ]

    };
  },
  watch: {
    selectId() {
      this.activeIndex = this.selectId;
    }
  },
  methods: {
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible; // 切换抽屉的显示状态
    },
    toggleDrawerMini() {
      this.drawerVisibleMini = !this.drawerVisibleMini; // 切换抽屉的显示状态
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  }
};
</script>

<style lang="scss" >
.miniOpen,
{
position: relative;
width: 100%;
pointer-events: none;

.nav_btn {
  width: 30px;
  height: 32px;
  position: absolute;
  right: 10px;
  // top: 46%;
  margin-top: -16px;
  overflow: hidden;
  cursor: pointer;
  pointer-events: auto;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: left 50%;
    border-radius: 1px;
    -webkit-transition: all 0.47s ease;
    -moz-transition: all 0.47s ease;
    -ms-transition: all 0.47s ease;
    transition: all 0.47s ease;
  }

  span.top {
    margin-top: -8px;

  }

  span.middle {}

  span.bottom {
    margin-top: 8px;

  }
}

.menu {
  position: absolute;
  top: -10px;
  left: -16px;
  font-size: 0.8rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  font-family: din_alternatebold;
}

.nav_btn.active {
  span.top {
    -webkit-transform: rotate(45deg) translate(0, -4px);
    -moz-transform: rotate(45deg) translate(0, -4px);
    -ms-transform: rotate(45deg) translate(0, -4px);
    transform: rotate(45deg) translate(0, -4px);
  }

  span.middle {
    opacity: 0;
  }

  span.bottom {
    -webkit-transform: rotate(-45deg) translate(0, 4px);
    -moz-transform: rotate(-45deg) translate(0, 4px);
    -ms-transform: rotate(-45deg) translate(0, 4px);
    transform: rotate(-45deg) translate(0, 4px);
  }
}
}

.bottom {
  padding: 0px;
}

.ha-header h1.logo::after {
  pointer-events: auto;
}

.drawerMiniBox,
.drawerBox {
  position: relative;
  z-index: 100;
  padding-left: 100px;
  display: flex;
  height: 100%;

  .navBox {
    width: calc(100% - 400px);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 80px;

    .el-menu {
      border-right: 0px solid #ffffff;


      .el-menu-item,
      .el-sub-menu__title {
        font-size: 1.1rem;
        border-radius: 6px;
        color: #ffffff !important;
      }

      .el-menu-item.is-active {
        color: #007E3C;
      }

      .el-menu-item:hover,
      .el-sub-menu:hover .el-sub-menu__title {
        background-color: rgba(0, 127, 225, 0.18) !important;
      }
    }


  }

  .ifnBox {
    width: 400px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: end;

    .addLogos {
      margin: 0;

      .addLogoBg {
        background-color: rgba(255, 255, 255, 0.4);

        img {
          width: 100%;
        }
      }

    }
  }

}

.drawerMiniBox {
  padding-left: 0;
}



@media (max-width: 991px) {
  .u-header--modern {
    .navbar-expand-lg.u-header__navbar {
      pointer-events: none;
    }
  }

  .u-header__navbar-brand-wrapper {
    pointer-events: none;
  }


}

@keyframes scroll {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes scroll1 {

  0%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes scale {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
</style>